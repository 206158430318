<template>
  <footer class="bg-dark py-5 ">
    <div class="container">
      <div class="row">
        <div class="col-md">
          <div class="mb-2 text-center text-md-left">
            <a href="https://bikeregistrada.com.br/" target="_blank">
              <img width="190" src="/img/logo.svg" alt="Logo Bike Registrada" />
            </a>
          </div>
          <div class="text-center d-md-none">
            <div class="redes-sociais">
              <div>Siga a gente</div>

              <a
                class="text-light mr-3"
                href="https://instagram.com/bikeregistrada"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a class="text-light mr-3" href="https://fb.com/bikeregistrada">
                <i class="fab fa-facebook-square"></i>
              </a>
              <a
                class="text-light mr-3"
                href="https://youtube.com/bikeregistrada"
              >
                <i class="fab fa-youtube"></i>
              </a>
              <a
                class="text-light mr-3"
                href="https://twitter.com/bikeregistrada"
              >
                <i class="fab fa-twitter-square"></i>
              </a>
            </div>
          </div>
          <div class="text-md-left">
            <nav class="nav flex-column">
              <a class="nav-link" href="https://bikeregistrada.com.br/lojas">Lojas parceiras</a>
              <a class="nav-link" href="https://blog.bikeregistrada.com.br">Nosso blog</a>
              <a class="nav-link" href="https://bikeregistrada.com.br/politicadeprivacidade.html">Política de privacidade</a>
              <a class="nav-link" href="https://bikeregistrada.com.br/termodeuso.html">Termo de uso</a>
            </nav>
          </div>
        </div>
        <div class="col-md text-md-left">
          <div>Fale com a gente!</div>
          <a
            class="d-block text-light mobile-target"
            target="_blank"
            href="tel:+556135224521"
            >+55 (61) 3522-4521</a
          >

          <a
            class="d-block text-light"
            href="mailto:contato@bikeregistrada.com.br?Subject=Selo%20Bike%20Registrada"
            target="_top"
            >contato@bikeregistrada.com.br</a
          >

          <small>
            <div class="mt-3">
              SCS QUADRA 04 BL A LOTE 219/237, S/N,
              <div class="d-inline-block">1º Andar - Ed. Vera Cruz</div>
            </div>
            <div>Asa Sul, Brasília-DF | CEP: 70.304-913</div>
            <div>CNPJ: 19.654.238/0001-69</div>
            <div>SUSEP: 15414.900790/2019-59</div>
          </small>
        </div>
        <div class="col-md d-none d-md-block mb-5 text-md-left">
          <div>
            <div class="redes-sociais">
              <div>Siga a gente</div>

              <a
                class="text-light mr-3"
                href="https://instagram.com/bikeregistrada"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a class="text-light mr-3" href="https://fb.com/bikeregistrada">
                <i class="fab fa-facebook-square"></i>
              </a>
              <a
                class="text-light mr-3"
                href="https://youtube.com/bikeregistrada"
              >
                <i class="fab fa-youtube"></i>
              </a>
              <a
                class="text-light mr-3"
                href="https://twitter.com/bikeregistrada"
              >
                <i class="fab fa-twitter-square"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr class="linha" />
      <div>© Bike Registrada © 2013 - 2022. Todos os direitos reservados</div>
    </div>
  </footer>
</template>

<script>
import config from "../config";
import formatacaoTelefone from "../helpers/formatacaoTelefone";

export default {
  props: ["smoothScroll", "menuHeight"],
  data() {
    return {
      config,
      formatacaoTelefone,
    };
  },
};
</script>

<style scoped>
.redes-sociais a {
  font-size: 32px;
}
.linha {
  color: rgb(255, 255, 255);
  border: 1px solid #ffffff;
}
.nav-link {
  color: rgba(255, 255, 255, 0.854) !important;
  line-height: 38px;
  padding: 0%;
}
.nav-link:hover {
  text-decoration: underline;
}

.mobile-target {
  min-height: 48px;
}
</style>
