export default {
  setLocalStorage: function (chave, value) {
    try {
      localStorage.setItem(chave, value);
      return true;
    } catch (exception) {
      return false;
    }
  },
  getLocalStorage: function (chave) {
    try {
      return localStorage.getItem(chave);
    } catch (exception) {
      return undefined;
    }
  },
  removeLocalStorage: function (chave) {
    try {
      localStorage.removeItem(chave);
      return true;
    } catch (exception) {
      return false;
    }
  },
  hasStorage: function () {
    try {
      localStorage.setItem("__hasStorageTest", true);
      localStorage.removeItem("__hasStorageTest");
      return true;
    } catch (exception) {
      return false;
    }
  },
  quebrarNome(nomeCompleto) {
    if (!nomeCompleto) {
      return nomeCompleto;
    }
    var idx = nomeCompleto.indexOf(" ");
    var primeiroNome = nomeCompleto.substr(0, idx);
    var segundoNome = nomeCompleto.substr(idx + 1);

    return [primeiroNome, segundoNome];
  },
  getUrlParameter(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search || location.hash);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  },
  obterExtensaoArquivo(nomeArquivo) {
    if (!nomeArquivo) {
      return;
    }

    var partesNomeArquivo = nomeArquivo.split(".");
    var extensaoArquivo = "." + partesNomeArquivo[partesNomeArquivo.length - 1];
    return extensaoArquivo;
  },
  formatacaoCpf(value) {
    if (value) {
      //retira os caracteres indesejados...
      value = value.replace(/[^\d]/g, "");

      //realizar a formatação...
      var cpf = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

      return cpf;
    }
  },
  emailMask(email) {
    if (!email) return;

    var maskedEmail = email
      .split("@")[0]
      .replace(/([^@.])/g, "*")
      .split("");
    for (var i = 0; i < maskedEmail.length; i++) {
      if (i <= parseInt(maskedEmail.length / 3)) {
        maskedEmail[i] = email[i];
      }
    }
    return `${maskedEmail.join("")}@${email.split("@")[1]}`;
  },
};
